@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  padding: 0;
  height: 100%;
  width: 100%;
  margin:0;
  background-color: #24292E;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
